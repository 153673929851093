import { autocomplete } from '@algolia/autocomplete-js'
import { useTranslation } from 'react-i18next'
import '@algolia/autocomplete-theme-classic'
import React, { createElement, Fragment, useEffect, useRef } from 'react'
import { render } from 'react-dom'

export default function Autocomplete(props) {
  const containerRef = useRef(null)

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const search = autocomplete({
      detachedMediaQuery: 'none',
      placeholder: t('search.placeholder'),
      container: containerRef.current,
      plugins: [],
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root)
      },
      ...props,
    })

    return () => {
      search.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return <div className="w-full" ref={containerRef} />
}
