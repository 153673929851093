import { useQueryClient } from 'react-query'
import useFirebase from '../useFirebase'

export default function useAccessToken() {
  const queryClient = useQueryClient()
  const { authUser } = useFirebase()

  return {
    getAccessToken: () => authUser?.getIdToken(),
    isSignedIn: !!authUser,
    invalidate: () => {
      queryClient.setQueryData(['user'], () => null)
    },
  }
}
