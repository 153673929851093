import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import RouteGuard from '../../components/RouteGuard'
import HeaderMenu from '../HeaderMenu'
import HeadLinks from '../HeadLinks'
import useUser from '../../hooks/useUser'
import useFirebase from '../../hooks/useFirebase'
import useOutdatedBuildRouter from '../../hooks/useOutdatedBuildRouter'
import AppContext from '../../hooks/useAppContext'

export default function Layout({ children }) {
  const { useGetMe } = useUser()
  const { user, refetch } = useGetMe()
  const { authUser } = useFirebase()
  const router = useOutdatedBuildRouter()

  const appContext = {
    user,
    router,
  }

  useEffect(() => {
    if (!authUser || user) {
      return
    }

    refetch()
  }, [authUser, user, refetch])

  const isHidingHeader =
    router.pathname === '/login' || router.pathname === '/settings'

  return (
    <>
      <Head>
        <title key="title">
          The Relationship Catalyst for your team | Relationyst{' '}
        </title>
        <link rel="canonical" href="" key="canonical" />
        <meta name="description" content="" key="description" />
        <meta name="keywords" content="" key="keywords" />

        <meta
          property="og:title"
          content="The Relationship Catalyst for your team | Relationyst"
          key="og:title"
        />
        <meta property="og:description" content="" key="og:description" />
        <meta property="og:url" content="" key="og:url" />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:image" content="" key="og:image" />
      </Head>
      <HeadLinks uri="/" />
      <AppContext.Provider value={appContext}>
        <RouteGuard publicPaths={['/login']}>
          {!isHidingHeader && <HeaderMenu user={user} />}
          {children}
        </RouteGuard>
      </AppContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
