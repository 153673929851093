export default function useAnonymousAnalytics() {
  const logEvent = (name) => {
    if (typeof window === 'undefined') {
      return
    }

    if (window.sa_event_loaded) {
      if (typeof window.sa_event === 'function') {
        window.sa_event(name)
      }
    }
  }

  const events = () => {
    return {
      create: {
        interaction: event('Create_Interaction'),
        customer: event('Create_Customer'),
        highlight: event('Create_Highlight'),
        followUp: event('Create_FollowUp'),
        favoriteCustomer: event('Create_FavoriteCustomer'),
        assignee: event('Create_Assignee'),
      },
      update: {
        interaction: event('Update_Interaction'),
        customer: event('Update_Customer'),
        highlight: event('Update_Highlight'),
        followUp: event('Update_FollowUp'),
      },
      delete: {
        interaction: event('Delete_Interaction'),
        customer: event('Delete_Customer'),
        highlight: event('Delete_Highlight'),
        followUp: event('Delete_FollowUp'),
        favoriteCustomer: event('Delete_FavoriteCustomer'),
        assignee: event('Delete_Assignee'),
      },
    }
  }

  const event = (prefix) => {
    return {
      started: `${prefix}_Started`,
      failed: `${prefix}_Failed`,
      finished: `${prefix}_Finished`,
    }
  }

  return {
    logEvent,
    events: events(),
  }
}
