import { useEffect, useRef, useState, useCallback } from 'react'
import {
  getAuth,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  updatePassword,
  signOut,
  EmailAuthProvider,
} from 'firebase/auth'
import getFirebaseApp from '../../helpers/firebaseApp'

export default function useFirebase() {
  const firebaseApp = getFirebaseApp()
  const auth = useRef(getAuth(firebaseApp))
  const [loadingUser, setLoadingUser] = useState(true)
  const [authUser, setAuthUser] = useState(null)
  const [signingIn, setSigningIn] = useState(false)
  const [error, setError] = useState(null)

  const signIn = useCallback(async ({ email, password }) => {
    setSigningIn(true)

    try {
      const authResult = await signInWithEmailAndPassword(
        auth.current,
        email,
        password,
      )

      return authResult
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setSigningIn(false)
    }
  }, [])

  useEffect(() => {
    auth.current.onAuthStateChanged((authUser) => {
      setAuthUser(authUser)
      setLoadingUser(false)
    })
  }, [])

  const _signOut = useCallback(() => {
    signOut(auth.current)
  }, [])

  const _updatePassword = useCallback(
    (password) => {
      return updatePassword(authUser, password)
    },
    [authUser],
  )

  const reauthenticate = useCallback(
    (email, password) => {
      const credential = EmailAuthProvider.credential(email, password)

      return reauthenticateWithCredential(authUser, credential)
    },
    [authUser],
  )

  return {
    signIn,
    error,
    signingIn,
    signOut: _signOut,
    loadingUser,
    authUser,
    updatePassword: _updatePassword,
    reauthenticate,
    clearError: () => setError(null),
  }
}
