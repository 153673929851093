import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

// WARNING: Only works outside <Head> tried to make it work
// but doesn't work when only returning the links not worth time lel
export default function HeadLinks({ uri }) {
  return (
    <Head>
      <link
        rel="canonical"
        href={`${process.env.NEXT_PUBLIC_SITE_BASE_URL}${uri}`}
        key="canonical"
      />
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_SITE_BASE_URL}${uri}`}
        key="og:url"
      />
    </Head>
  )
}

HeadLinks.propTypes = {
  uri: PropTypes.string.isRequired,
}
