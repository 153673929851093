import { initializeApp, getApp } from 'firebase/app'
import firebaseConfig from '../firebaseConfig'

const getFirebaseApp = () => {
  try {
    return getApp()
  } catch (err) {
    return initializeApp(firebaseConfig)
  }
}

export default getFirebaseApp
