/* eslint-disable react/display-name */
import React from 'react'
import cx from 'classnames'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline'
import PropTypes from 'prop-types'
import Spinner from '../../assets/spinner.svg'

const CTAButton = React.forwardRef(
  (
    {
      className,
      text,
      disabled,
      loading,
      success,
      error,
      onClick,
      progressText,
      errorText,
      successText,
      isSubmit,
    },
    ref,
  ) => {
    const getContent = () => {
      if (loading) {
        return (
          <div className="flex items-center">
            <Spinner
              width={18}
              height={18}
              className={cx('text-gray-800', {
                'mr-1': !!progressText,
              })}
            />
            <span>{progressText}</span>
          </div>
        )
      }

      if (success) {
        return (
          <div className="flex items-center">
            <CheckCircleIcon
              width={18}
              height={18}
              className={cx('text-green-800', {
                'mr-1': !!successText,
              })}
            />
            <span>{successText}</span>
          </div>
        )
      }

      if (error) {
        return (
          <div className="flex items-center">
            <ExclamationCircleIcon
              width={18}
              height={18}
              className={cx('text-red-800', {
                'mr-1': !!errorText,
              })}
            />
            <span>{errorText}</span>
          </div>
        )
      }

      return text
    }

    return (
      <button
        ref={ref}
        className={cx(
          'flex items-center justify-center font-body focus:ring-4 focus:ring-opacity-50 focus:ring-offset-2 font-bold py-2 px-4 rounded-md focus:outline-none disabled:cursor-not-allowed',
          {
            'bg-gray-300 text-gray-800': loading,
            'bg-green-300 hover:bg-green-400 text-green-800 focus:ring-green-500':
              success,
            'bg-red-300 hover:bg-red-400 disabled:bg-red-300 text-red-800 focus:ring-red-500':
              error,
            'bg-indigo-500 text-white hover:bg-indigo-800 disabled:bg-gray-400 disabled:text-gray-800 focus:ring-indigo-500':
              !success && !error && !loading,
          },
          className,
        )}
        type={isSubmit ? 'submit' : 'button'}
        disabled={disabled || loading}
        onClick={onClick}>
        {getContent()}
      </button>
    )
  },
)

export default CTAButton

CTAButton.propTypes = {
  className: PropTypes.string,
  progressText: PropTypes.string,
  errorText: PropTypes.string,
  successText: PropTypes.string,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  isSubmit: PropTypes.bool,
}

CTAButton.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  error: false,
  success: false,
  isSubmit: false,
}
