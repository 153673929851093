import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import * as Sentry from '@sentry/nextjs'
import useAccessToken from '../useAccessToken'
import useAnonymousAnalytics from '../useAnonymousAnalytics'
import useApi from '../useApi'
import useDate from '../useDate'

export default function useCustomer() {
  const client = useQueryClient()
  const { getAccessToken, isSignedIn } = useAccessToken()
  const {
    getCustomerById,
    getCustomers,
    createCustomer,
    deleteCustomer,
    updateCustomer,
    createInteraction,
    updateInteraction,
    deleteInteraction,
    createHighlight,
    deleteHighlight,
    createFollowUp,
    deleteFollowUp,
    updateFollowUp,
    addFavoriteCustomer,
    deleteFavoriteCustomer,
    assignUserToCustomer,
    deleteAssignedUserFromCustomer,
  } = useApi({ getAccessToken })
  const { logEvent, events } = useAnonymousAnalytics()
  const { dateString } = useDate()

  function useGetCustomer({ organizationId, customerId }) {
    const result = useQuery(
      ['customers', { customerId }],
      () => getCustomerById({ id: customerId, organizationId }),
      {
        enabled:
          customerId !== undefined &&
          organizationId !== undefined &&
          isSignedIn,
        refetchOnWindowFocus: 'always',
      },
    )

    return {
      ...result,
      customer: result.data,
    }
  }

  function useGetInfiniteCustomers({
    organizationId,
    userId,
    sort,
    direction,
    listMode,
  }) {
    const result = useInfiniteQuery(
      ['customerList', { organizationId, userId, sort, direction, listMode }],
      ({ pageParam = 1 }) =>
        getCustomers({
          organizationId,
          userId,
          view: 'list',
          page: pageParam,
          per: '25',
          sort,
          listMode,
          direction,
        }),
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.meta.prev_page || undefined,
        getNextPageParam: (lastPage) => lastPage.meta.next_page || undefined,
        enabled: !!organizationId && isSignedIn,
      },
    )

    return {
      ...result,
      customerPages: result.data?.pages,
    }
  }

  function useGetCreatedCustomers({ organizationId }) {
    const result = useQuery(
      ['createdCustomers', { organizationId }],
      async () => {
        const data = await client.getQueryData([
          'createdCustomers',
          { organizationId },
        ])

        return data || []
      },
    )

    return {
      ...result,
      customers: result.data,
    }
  }

  function useGetDeletedCustomers({ organizationId }) {
    const result = useQuery(
      ['deletedCustomers', { organizationId }],
      async () => {
        const data = await client.getQueryData([
          'deletedCustomers',
          { organizationId },
        ])

        return data || []
      },
    )

    return {
      ...result,
      customers: result.data,
    }
  }

  function useCreateCustomer() {
    const result = useMutation(
      ({ organizationId, data }) => createCustomer({ organizationId, data }),
      {
        onMutate() {
          logEvent(events.create.customer.started)
        },
        onSuccess(createdCustomer, { organizationId }) {
          addCustomerToCache({ customer: createdCustomer, organizationId })
          invalidateCustomers(organizationId)
          logEvent(events.create.customer.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.create.customer.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      createCustomer: result.mutate,
    }
  }

  function useDeleteCustomer() {
    const result = useMutation(
      ({ organizationId, id }) => deleteCustomer({ id, organizationId }),
      {
        onMutate() {
          logEvent(events.delete.customer.started)
        },
        onSuccess(_, { organizationId, id }) {
          invalidateCustomers(organizationId)
          logEvent(events.delete.customer.finished)

          client.setQueryData(
            ['createdCustomers', { organizationId }],
            (old) => {
              return (old || []).filter((c) => c.id !== id)
            },
          )

          client.setQueryData(
            ['deletedCustomers', { organizationId }],
            (old) => {
              return [id, ...(old || [])]
            },
          )

          client.invalidateQueries(['createdCustomers', { organizationId }])
          client.invalidateQueries(['deletedCustomers', { organizationId }])
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.delete.customer.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      deleteCustomer: result.mutate,
    }
  }

  const addCustomerToCache = ({ organizationId, customer }) => {
    client.setQueryData(['customers', { customerId: customer.id }], customer)

    client.setQueryData(['createdCustomers', { organizationId }], (old) => {
      return [customer, ...(old || [])].sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })
    })
  }

  const invalidateCustomers = () => {
    client.refetchQueries(['customerList'])
  }

  function useUpdateCustomer() {
    const result = useMutation(
      ({ id, organizationId, data }) =>
        updateCustomer({ id, organizationId, data }),
      {
        onMutate({ id, data }) {
          if (client.getQueryData(['customers', { customerId: id }])) {
            client.setQueryData(['customers', { customerId: id }], (old) => {
              return {
                ...old,
                ...data,
              }
            })
          } else {
            Sentry.captureException('No customer in the cache')
          }
          logEvent(events.update.customer.started)
        },
        onSuccess(_, { organizationId }) {
          invalidateCustomers(organizationId)
          client.invalidateQueries('follow_ups')
          logEvent(events.update.customer.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.update.customer.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      updateCustomer: result.mutate,
    }
  }

  function useCreateHighlight() {
    const result = useMutation(
      ({ customerId, organizationId, data }) =>
        createHighlight({ organizationId, customerId, data }),
      {
        onMutate() {
          logEvent(events.create.highlight.started)
        },
        onSuccess(createdHighlight, { customerId }) {
          if (client.getQueryData(['customers', { customerId }])) {
            client.setQueryData(['customers', { customerId }], (old) => {
              return {
                ...old,
                highlights: [createdHighlight, ...old.highlights],
              }
            })
          } else {
            Sentry.captureException('No customer in the cache')
          }
          logEvent(events.create.highlight.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.create.highlight.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      createHighlight: result.mutateAsync,
    }
  }

  function useDeleteHighlight() {
    const result = useMutation(
      async ({ id, customerId, organizationId }) => {
        try {
          const res = await deleteHighlight({ id, customerId, organizationId })

          return res
        } catch (err) {
          // was already deleted
          if (err.status === 403) {
            return
          }
          throw err
        }
      },
      {
        onMutate({ id, customerId }) {
          if (client.getQueryData(['customers', { customerId }])) {
            client.setQueryData(['customers', { customerId }], (old) => {
              const updatedCustomer = {
                ...old,
                highlights: old.highlights.filter((h) => h.id !== id),
              }

              return updatedCustomer
            })
          } else {
            Sentry.captureException('No customer in the cache')
          }
          logEvent(events.delete.highlight.started)
        },
        onSuccess() {
          logEvent(events.delete.highlight.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.delete.highlight.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      deleteHighlight: result.mutate,
    }
  }

  function useCreateInteraction() {
    const result = useMutation(
      ({ customerId, organizationId, data }) =>
        createInteraction({ organizationId, customerId, data }),
      {
        onMutate() {
          logEvent(events.create.interaction.started)
        },
        onSuccess(createdInteraction, { customerId, organizationId }) {
          if (client.getQueryData(['customers', { customerId }])) {
            client.setQueryData(['customers', { customerId }], (old) => {
              return {
                ...old,
                interactions: [createdInteraction, ...old.interactions],
              }
            })
          } else {
            Sentry.captureException('No customer in the cache')
          }
          invalidateCustomers(organizationId)
          logEvent(events.create.interaction.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.create.interaction.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      createInteraction: result.mutate,
    }
  }

  function useUpdateInteraction() {
    const result = useMutation(
      ({ id, organizationId, customerId, data }) =>
        updateInteraction({ id, organizationId, customerId, data }),
      {
        onMutate: async ({ id, customerId, data }) => {
          if (client.getQueryData(['customers', { customerId }])) {
            client.setQueryData(['customers', { customerId }], (old) => {
              const index = old.interactions.findIndex((i) => i.id === id)

              const updatedCustomer = {
                ...old,
                interactions: [
                  ...old.interactions.slice(0, index),
                  data,
                  ...old.interactions.slice(index + 1),
                ],
              }

              return updatedCustomer
            })
          } else {
            Sentry.captureException('No customer in the cache')
          }
          logEvent(events.update.interaction.started)
        },
        onSuccess(_, { organizationId }) {
          invalidateCustomers(organizationId)
          logEvent(events.update.interaction.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.update.interaction.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      updateInteraction: result.mutate,
    }
  }

  function useDeleteInteraction() {
    const result = useMutation(
      ({ id, customerId, organizationId }) => {
        deleteInteraction({ organizationId, customerId, id })
      },
      {
        onMutate({ id, customerId }) {
          if (client.getQueryData(['customers', { customerId }])) {
            client.setQueryData(['customers', { customerId }], (old) => {
              const updatedCustomer = {
                ...old,
                interactions: old.interactions.filter((u) => u.id !== id),
                highlights: old.highlights.filter((h) => h.updateId !== id),
              }

              return updatedCustomer
            })
          } else {
            Sentry.captureException('No customer in the cache')
          }
          logEvent(events.delete.interaction.started)
        },
        onSuccess(_, { organizationId }) {
          invalidateCustomers(organizationId)
          logEvent(events.delete.interaction.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.delete.interaction.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      deleteInteraction: result.mutate,
    }
  }

  function useCreateFollowUp() {
    const result = useMutation(
      ({ customerId, organizationId, data }) =>
        createFollowUp({ organizationId, customerId, data }),
      {
        onMutate() {
          logEvent(events.create.followUp.started)
        },
        onSuccess(createdFollowUp, { customerId, organizationId }) {
          invalidateCustomers(organizationId)
          client.invalidateQueries([
            'follow_ups',
            {
              date: createdFollowUp.date,
              userId: createdFollowUp.user.id,
              organizationId,
            },
          ])

          if (!client.getQueryData(['customers', { customerId }])) {
            Sentry.captureException('No customer in the cache')

            return
          }
          client.setQueryData(['customers', { customerId }], (old) => {
            return {
              ...old,
              follow_ups: [createdFollowUp, ...old.follow_ups],
            }
          })
          logEvent(events.create.followUp.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.create.followUp.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      createFollowUp: result.mutate,
    }
  }

  function useUpdateFollowUp() {
    const result = useMutation(
      ({ id, customerId, organizationId, change }) =>
        updateFollowUp({ id: id, organizationId, customerId, data: change }),
      {
        onMutate: async ({ id, customerId, user, change }) => {
          const existingData = client.getQueryData([
            'customers',
            { customerId },
          ])

          if (!existingData) {
            Sentry.captureException('No customer in the cache')

            return
          }
          client.setQueryData(['customers', { customerId }], (old) => {
            const follow_ups = old.follow_ups || []
            const index = follow_ups.findIndex((f) => f.id === id)
            let oldFollowUp = follow_ups[index]

            if (user) {
              oldFollowUp = { ...oldFollowUp, user }
            }

            const updatedCustomer = {
              ...old,
              follow_ups: [
                ...follow_ups.slice(0, index),
                { ...oldFollowUp, ...change },
                ...follow_ups.slice(index + 1),
              ],
            }

            return updatedCustomer
          })

          logEvent(events.update.followUp.started)
        },
        onSuccess: (updatedFollowUp, { currentDate, organizationId }) => {
          client.invalidateQueries([
            'follow_ups',
            {
              date: updatedFollowUp.date,
              userId: updatedFollowUp.user.id,
              organizationId,
            },
          ])

          invalidateCustomers(organizationId)

          // this can be done in the mutate as well
          if (currentDate) {
            client.invalidateQueries([
              'follow_ups',
              {
                date: currentDate,
                userId: updatedFollowUp.user.id,
                organizationId,
              },
            ])
            client.invalidateQueries([
              'follow_ups',
              {
                date: dateString(new Date()),
                userId: updatedFollowUp.user.id,
                organizationId,
              },
            ])
          }

          logEvent(events.update.followUp.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.update.followUp.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      updateFollowUp: result.mutate,
    }
  }

  function useDeleteFollowUp() {
    const result = useMutation(
      ({ id, customerId, organizationId }) =>
        deleteFollowUp({ organizationId, customerId, id }),
      {
        onMutate({ id, customerId }) {
          if (client.getQueryData(['customers', { customerId }])) {
            client.setQueryData(['customers', { customerId }], (old) => {
              const updatedCustomer = {
                ...old,
                follow_ups: old.follow_ups.filter((f) => f.id !== id),
              }

              return updatedCustomer
            })
          } else {
            Sentry.captureException('No customer in the cache')
          }

          logEvent(events.delete.followUp.started)
        },
        onSuccess: (deletedFollowUp, { organizationId }) => {
          client.invalidateQueries([
            'follow_ups',
            {
              date: deletedFollowUp.date,
              userId: deletedFollowUp.user.id,
              organizationId,
            },
          ])

          invalidateCustomers(organizationId)
          logEvent(events.delete.followUp.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.delete.followUp.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      deleteFollowUp: result.mutate,
    }
  }

  function useAddToFavorites() {
    const result = useMutation(
      ({ customerId, organizationId }) =>
        addFavoriteCustomer({
          organizationId,
          data: { customer_id: customerId },
        }),
      {
        onMutate: async ({ customerId }) => {
          const existingData = client.getQueryData([
            'customers',
            { customerId },
          ])

          if (!existingData) {
            Sentry.captureException('No customer in the cache')

            return
          }
          client.setQueryData(['customers', { customerId }], (old) => {
            return {
              ...old,
              favorite: true,
            }
          })

          logEvent(events.create.favoriteCustomer.started)
        },
        onSuccess: (_, { organizationId }) => {
          invalidateCustomers(organizationId)

          logEvent(events.create.favoriteCustomer.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.create.favoriteCustomer.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      addToFavorites: result.mutateAsync,
    }
  }

  function useDeleteFavorite() {
    const result = useMutation(
      ({ customerId, organizationId }) =>
        deleteFavoriteCustomer({
          organizationId,
          customerId,
        }),
      {
        onMutate: async ({ customerId }) => {
          const existingData = client.getQueryData([
            'customers',
            { customerId },
          ])

          if (!existingData) {
            Sentry.captureException('No customer in the cache')

            return
          }
          client.setQueryData(['customers', { customerId }], (old) => {
            return {
              ...old,
              favorite: false,
            }
          })

          logEvent(events.delete.favoriteCustomer.started)
        },
        onSuccess: (_, { organizationId }) => {
          invalidateCustomers(organizationId)

          logEvent(events.delete.favoriteCustomer.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.delete.favoriteCustomer.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      deleteFavorite: result.mutateAsync,
    }
  }

  function useAddAsignee() {
    const result = useMutation(
      ({ customerId, organizationId, user }) =>
        assignUserToCustomer({
          organizationId,
          customerId,
          data: { user_id: user.id },
        }),
      {
        onMutate: async ({ customerId, user }) => {
          const existingData = client.getQueryData([
            'customers',
            { customerId },
          ])

          if (!existingData) {
            Sentry.captureException('No customer in the cache')

            return
          }
          client.setQueryData(['customers', { customerId }], (old) => {
            return {
              ...old,
              assignees: (old.assignees || []).concat([user]),
            }
          })

          logEvent(events.create.assignee.started)
        },
        onSuccess: (_, { organizationId }) => {
          invalidateCustomers(organizationId)

          logEvent(events.create.assignee.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.create.assignee.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      addAssignee: result.mutateAsync,
    }
  }

  function useRemoveAssignee() {
    const result = useMutation(
      ({ customerId, organizationId, userId }) =>
        deleteAssignedUserFromCustomer({
          organizationId,
          customerId,
          userId,
        }),
      {
        onMutate: async ({ customerId, userId }) => {
          const existingData = client.getQueryData([
            'customers',
            { customerId },
          ])

          if (!existingData) {
            Sentry.captureException('No customer in the cache')

            return
          }
          client.setQueryData(['customers', { customerId }], (old) => {
            return {
              ...old,
              assignees: (old.assignees || []).filter((f) => f.id !== userId),
            }
          })

          logEvent(events.delete.assignee.started)
        },
        onSuccess: (_, { organizationId }) => {
          invalidateCustomers(organizationId)

          logEvent(events.delete.assignee.finished)
        },
        onError(err) {
          Sentry.captureException(err)
          logEvent(events.delete.assignee.failed)
        },
      },
      {
        retry: 3,
      },
    )

    return {
      ...result,
      removeAssignee: result.mutateAsync,
    }
  }

  return {
    useCreateCustomer,
    useGetCustomer,
    useGetInfiniteCustomers,
    useGetCreatedCustomers,
    useGetDeletedCustomers,
    useUpdateCustomer,
    useDeleteCustomer,
    useCreateHighlight,
    useDeleteHighlight,
    useCreateInteraction,
    useUpdateInteraction,
    useDeleteInteraction,
    useCreateFollowUp,
    useUpdateFollowUp,
    useDeleteFollowUp,
    useAddToFavorites,
    useDeleteFavorite,
    useAddAsignee,
    useRemoveAssignee,
  }
}
