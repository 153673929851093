import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const LOCALES = ['en', 'de']
const loadResources = () => {
  var result = {}

  LOCALES.map((locale) => {
    var translation = require(`./public/locales/${locale}/common.json`)
    result[locale] = {
      translation: {
        ...translation,
      },
    }
  })

  return result
}

const resources = loadResources()

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    prefix: '%{',
    suffix: '}',
  },
})

export default i18n
