/* eslint-disable @next/next/no-html-link-for-pages */
import React, { useState, useEffect } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types'
import useTranslation from '../../hooks/useTranslation'
import CTAButton from '../CTAButton'

export default function ErrorBoundaryFallback({ error }) {
  const [showError, setShowError] = useState(true)
  const t = useTranslation()

  useEffect(() => {
    setShowError(!!error)
  }, [error])

  if (!showError) {
    return null
  }

  return (
    <div
      className="flex flex-col items-center justify-center text-gray-600"
      style={{ height: 'calc(100vh - 64px)' }}>
      <ExclamationCircleIcon className="w-8 h-8" />
      <p className="mt-2 font-bold">{t('error.message')}</p>
      <a href="/" className="mt-6">
        <CTAButton
          text={t('error.buttonText')}
          onClick={() => setShowError(false)}
        />
      </a>
      <div className="flex items-center mt-2">
        <span className="mr-1 text-xs">{t('error.or')}</span>
        <a href="mailto:oi@nichemat.es" className="text-xs underline">
          {t('error.ask')}
        </a>
      </div>
    </div>
  )
}

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.object,
}
