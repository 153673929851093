import { useTranslation as _useTranslation } from 'react-i18next'

export default function useTranslation() {
  const { t } = _useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  return t
}
