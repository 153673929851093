/* eslint-disable @next/next/no-img-element */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import Search from '../../components/Search'
import useFirebase from '../../hooks/useFirebase'
import useAccessToken from '../../hooks/useAccessToken'
import useUser from '../../hooks/useUser'
import useCustomer from '../../hooks/useCustomer'
import { useApp } from '../../hooks/useAppContext'
import Logo from '../../assets/logo.svg'

export default function HeaderMenu({ user }) {
  const { signOut } = useFirebase()
  const { invalidate } = useAccessToken()
  const { useGetCurrentOrganizationId, useSetCurrentOrganizationId } = useUser()
  const { useGetCreatedCustomers, useGetDeletedCustomers } = useCustomer()
  const { currentOrganizationId } = useGetCurrentOrganizationId({ user })
  const { customers: cachedCustomers } = useGetCreatedCustomers({
    organizationId: currentOrganizationId,
  })
  const { customers: deletedCustomers } = useGetDeletedCustomers({
    organizationId: currentOrganizationId,
  })
  const { setCurrentOrganizationId, invalidate: invalidateOrgId } =
    useSetCurrentOrganizationId()

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const { router } = useApp()

  const onSignOut = async () => {
    await signOut()
    invalidate()
    invalidateOrgId()
  }

  const onSwitchOrganization = (organizationId) => {
    setCurrentOrganizationId(organizationId)
  }

  const currentOrg = () => {
    return user?.organizations.find((o) => o.id === currentOrganizationId)
  }

  return (
    <div className="flex items-center justify-between w-full h-16 px-2 py-2 bg-indigo-500 sm:px-4">
      <div className="relative hidden sm:block">
        <Link href="/">
          <a>
            <Logo className="w-auto h-8 text-white" />
          </a>
        </Link>
      </div>
      {user && (
        <div className="flex-grow max-w-2xl mx-4">
          <Search
            customers={cachedCustomers}
            deletedCustomers={deletedCustomers}
          />
        </div>
      )}
      {user && (
        <Menu
          as="div"
          className="relative z-50 flex-shrink-0 inline-block text-left">
          <div>
            <Menu.Button className="flex flex-col text-sm rounded-full focus:outline-none focus:underline decoration-indigo-100">
              <span className="sr-only">Open user menu</span>
              <p className="w-full text-xs text-right text-indigo-800">
                {currentOrg()?.name ?? ''}
              </p>
              <p className="w-full text-right text-indigo-100">
                Hey, {user?.first_name} 👋
              </p>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item className="py-2 ml-4 text-xs text-gray-400">
                  <p>
                    {user?.name} {}
                  </p>
                </Menu.Item>
                {user?.organizations.map((o) => (
                  <Menu.Item key={o.id}>
                    {({ active }) => (
                      <button
                        onClick={() => onSwitchOrganization(o.id)}
                        className={cx(
                          'w-full text-left block px-4 py-2 text-sm text-gray-700',
                          {
                            'bg-gray-100': active,
                            'bg-indigo-200 text-indigo-800':
                              currentOrganizationId === o.id,
                          },
                        )}>
                        {o.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => router.push('settings')}
                      className={cx(
                        'w-full text-left block px-4 py-2 text-sm text-gray-700',
                        {
                          'bg-gray-100': active,
                        },
                      )}>
                      {t('settings.title')}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={onSignOut}
                      className={cx(
                        'w-full text-left block px-4 py-2 text-sm text-gray-700',
                        {
                          'bg-gray-100': active,
                        },
                      )}>
                      {t('menu.signOut')}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  )
}

HeaderMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    first_name: PropTypes.string,
    organizations: PropTypes.array,
  }),
}
