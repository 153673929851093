import React from 'react'
import 'tailwindcss/tailwind.css'
import '@fontsource/work-sans/300.css'
import '@fontsource/work-sans/400.css'
import '@fontsource/work-sans/500.css'
import '@fontsource/work-sans/600.css'
import '@fontsource/work-sans/700.css'
import '@fontsource/work-sans/800.css'
import '@fontsource/work-sans/900.css'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ErrorBoundary } from '@sentry/react'
import { I18nextProvider } from 'react-i18next'
import { ReactQueryDevtools } from 'react-query/devtools'
import { TgglClient, TgglProvider } from 'react-tggl-client'
import Layout from '../components/Layout'
import Analytics from '../components/Analytics'
import ErrorBoundaryFallback from '../components/ErrorBoundaryFallback'
import './index.css'
import i18n from '../i18n'
import useLanguageParser from '../hooks/useLanguageParser'

const queryClient = new QueryClient()
const client = new TgglClient(process.env.NEXT_PUBLIC_TGGL_API_KEY)

// eslint-disable-next-line react/prop-types
function MyApp({ Component, pageProps, locale }) {
  i18n.changeLanguage(locale)

  return (
    <ErrorBoundary
      fallback={({ error }) => {
        return <ErrorBoundaryFallback error={error} />
      }}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <TgglProvider client={client}>
            <Layout>
              <Component {...pageProps} locale={locale} />
              <Analytics />
            </Layout>
            <ReactQueryDevtools initialIsOpen={false} />
          </TgglProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = async ({ ctx }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { parse } = useLanguageParser()
  const locale = parse(ctx)

  return {
    locale,
  }
}

export default MyApp
