/* eslint-disable react/prop-types */
import React from 'react'
import algoliasearch from 'algoliasearch'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import usePersonalities from '../../hooks/usePersonalities'
import useUser from '../../hooks/useUser'
import { useApp } from '../../hooks/useAppContext'
import Autocomplete from './autocomplete'

function uniqBy(predicate) {
  return function runUniqBy(...rawSources) {
    const sources = rawSources.flat().filter(Boolean)
    const seen = new Set()

    return sources.map((source) => {
      const items = source.getItems().filter((item) => {
        const appliedItem = predicate({ source, item })
        const hasSeen = seen.has(appliedItem)

        seen.add(appliedItem)

        return !hasSeen
      })

      return {
        ...source,
        getItems() {
          return items
        },
      }
    })
  }
}

export default function Search({ customers, deletedCustomers }) {
  const { router } = useApp()
  const { getById } = usePersonalities()
  const { useGetMe, useGetCurrentOrganizationId } = useUser()
  const { user } = useGetMe()
  const { currentOrganizationId } = useGetCurrentOrganizationId({ user })

  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
    user?.search_api_key,
  )

  const removeDuplicates = uniqBy(({ item }) => item.hashid || item.id)

  const searchResultTemplate = (query) => {
    return {
      item({ item }) {
        const highlights = (name) => {
          const index = name.toLowerCase().indexOf(query.toLowerCase().trim())
          const endIndex = index + query.toLowerCase().trim().length

          return (
            <>
              <span>{name.substring(0, index)}</span>
              <span className="bg-indigo-200">
                {name.substring(index, endIndex)}
              </span>
              <span>{name.substring(endIndex)}</span>
            </>
          )
        }

        return (
          <a
            className="aa-ItemLink"
            onClick={() =>
              router.push(item.query, item.slug, { shallow: true })
            }>
            <div className="aa-ItemContent">
              <div className="text-gray-800 aa-ItemTitle">
                <>
                  <span>{getById(item.personality).icon}</span>{' '}
                  {highlights(item.name)}
                </>
              </div>
            </div>
          </a>
        )
      },
    }
  }

  return (
    <Autocomplete
      openOnFocus={true}
      navigator={{
        navigate({ itemUrl }) {
          const id = itemUrl.split('/c/').pop()
          router.push(`?c=${id}`, itemUrl, { shallow: true })
        },
      }}
      getSources={({ query }) => [
        {
          sourceId: 'remoteCustomers',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: `Customer_${process.env.NODE_ENV}`,
                  query,
                  params: {
                    hitsPerPage: query === '' ? 10 : 1000,
                    filters: `visible_by:${currentOrganizationId}`,
                  },
                },
              ],
              transformResponse({ hits }) {
                return hits
                  .flat()
                  .filter(
                    (c) => (deletedCustomers || []).indexOf(c.hashid) === -1,
                  )
                  .map((c) => {
                    return {
                      id: c.hashid,
                      personality: c.personality,
                      name: c.name,
                      slug: `/c/${c.hashid}`,
                      query: `?c=${c.hashid}`,
                    }
                  })
              },
            })
          },
          getItemUrl({ item }) {
            return item.slug
          },
          templates: searchResultTemplate(query),
        },
        {
          sourceId: 'localCustomers',
          getItems() {
            return (customers || [])
              .map((c) => {
                return {
                  id: c.id,
                  personality: c.personality,
                  name: c.name,
                  slug: `/c/${c.id}`,
                  query: `?c=${c.id}`,
                }
              })
              .filter(({ name }) =>
                name.toLowerCase().includes(query.toLowerCase().trim()),
              )
          },
          getItemUrl({ item }) {
            return item.slug
          },
          templates: searchResultTemplate(query),
        },
      ]}
      reshape={({ sourcesBySourceId }) => {
        const { remoteCustomers, localCustomers, ...rest } = sourcesBySourceId

        return [
          removeDuplicates(remoteCustomers, localCustomers),
          Object.values(rest),
        ]
      }}
    />
  )
}
