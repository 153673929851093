import { useState } from 'react'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'

export default function useOutdatedBuildRouter() {
  const [outdatedBuild, setOutdatedBuild] = useState(false)
  const router = useRouter()

  useQuery(
    ['buildId'],
    async () => {
      const res = await fetch('/api/build')

      if (!res.ok) {
        throw new Error(res.statusText)
      }

      const { buildId } = await res.json()

      if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
        setOutdatedBuild(true)
      }

      return buildId
    },
    { refetchInterval: 30000 },
  )

  const push = (url, as, options) => {
    if (outdatedBuild) {
      window.location = url
      setOutdatedBuild(false)
    } else {
      router.push(url, as, options)
    }
  }

  return {
    ...router,
    push,
  }
}
