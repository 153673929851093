import parser from 'accept-language-parser'
const ALLOWED_LOCALES = ['en', 'de']

export default function useLanguageParser() {
  const parse = (ctx) => {
    return parser.pick(ALLOWED_LOCALES, ctx.req?.headers['accept-language'])
  }

  return { parse }
}
