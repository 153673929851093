/* eslint-disable max-len */
class ApiError extends Error {
  constructor({ statusText, status }) {
    super(statusText)
    this.name = 'ApiError'
    this.status = status
  }
}

export default function useApi({ getAccessToken }) {
  const request = async (method, path, data) => {
    const options = {
      method: method.toUpperCase(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    if (getAccessToken) {
      options.headers.authorization = `Bearer ${await getAccessToken()}`
    }

    let url = `${process.env.NEXT_PUBLIC_API_BASE_URL}${path}`

    if (method === 'GET') {
      if (data) {
        url = `${url}?${new URLSearchParams(data).toString()}`
      }
    } else {
      options.body = JSON.stringify(data || {})
    }

    const res = await fetch(url, options)

    if (!res.ok) {
      throw new ApiError(res)
    }

    return await res.json()
  }

  const getMe = () => request('GET', 'me')
  const getUserFollowUps = ({ organizationId, userId, date }) =>
    request(
      'GET',
      `organizations/${organizationId}/users/${userId}/follow_ups`,
      { date },
    )
  const getOrganization = ({ organizationId }) =>
    request('GET', `organizations/${organizationId}`)
  const getCustomerById = ({ id, organizationId }) =>
    request('GET', `organizations/${organizationId}/customers/${id}`)
  const getCustomers = (
    {
      organizationId,
      userId,
      view = 'default',
      page = '1',
      per = '',
      sort = 'name',
      listMode = 'everybody',
      direction = '',
    } = {
      view: 'default',
      page: '1',
      per: '',
      sort: 'name',
      listMode: 'everybody',
      direction: '',
    },
  ) =>
    request('GET', `organizations/${organizationId}/customers`, {
      view,
      page,
      per,
      sort,
      direction,
      user_id: userId,
      list_mode: listMode,
    })
  const createCustomer = ({ organizationId, data }) =>
    request('POST', `organizations/${organizationId}/customers`, data)
  const deleteCustomer = ({ id, organizationId }) =>
    request('DELETE', `organizations/${organizationId}/customers/${id}`)
  const updateCustomer = ({ organizationId, id, data }) =>
    request('PUT', `organizations/${organizationId}/customers/${id}`, data)

  const createInteraction = ({ organizationId, customerId, data }) =>
    request(
      'POST',
      `organizations/${organizationId}/customers/${customerId}/interactions`,
      data,
    )
  const updateInteraction = ({ organizationId, customerId, id, data }) =>
    request(
      'PUT',
      `organizations/${organizationId}/customers/${customerId}/interactions/${id}`,
      data,
    )
  const deleteInteraction = ({ organizationId, customerId, id }) =>
    request(
      'DELETE',
      `organizations/${organizationId}/customers/${customerId}/interactions/${id}`,
    )

  const createHighlight = ({ organizationId, customerId, data }) =>
    request(
      'POST',
      `organizations/${organizationId}/customers/${customerId}/highlights`,
      data,
    )
  const deleteHighlight = ({ organizationId, customerId, id }) =>
    request(
      'DELETE',
      `organizations/${organizationId}/customers/${customerId}/highlights/${id}`,
    )

  const createFollowUp = ({ organizationId, customerId, data }) =>
    request(
      'POST',
      `organizations/${organizationId}/customers/${customerId}/follow_ups`,
      data,
    )

  const updateFollowUp = ({ organizationId, customerId, id, data }) =>
    request(
      'PUT',
      `organizations/${organizationId}/customers/${customerId}/follow_ups/${id}`,
      data,
    )

  const deleteFollowUp = ({ organizationId, customerId, id }) =>
    request(
      'DELETE',
      `organizations/${organizationId}/customers/${customerId}/follow_ups/${id}`,
    )

  const addFavoriteCustomer = ({ organizationId, data }) =>
    request('POST', `organizations/${organizationId}/favorites`, data)

  const deleteFavoriteCustomer = ({ organizationId, customerId }) =>
    request('DELETE', `organizations/${organizationId}/favorites/${customerId}`)

  const assignUserToCustomer = ({ organizationId, customerId, data }) =>
    request(
      'POST',
      `organizations/${organizationId}/customers/${customerId}/assignees`,
      data,
    )

  const deleteAssignedUserFromCustomer = ({
    organizationId,
    customerId,
    userId,
  }) =>
    request(
      'DELETE',
      `organizations/${organizationId}/customers/${customerId}/assignees/${userId}`,
    )

  return {
    getMe,
    getUserFollowUps,
    getOrganization,
    getCustomers,
    getCustomerById,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    createInteraction,
    updateInteraction,
    deleteInteraction,
    createHighlight,
    deleteHighlight,
    createFollowUp,
    updateFollowUp,
    deleteFollowUp,
    addFavoriteCustomer,
    deleteFavoriteCustomer,
    assignUserToCustomer,
    deleteAssignedUserFromCustomer,
  }
}
