import useTranslation from '../useTranslation'

export default function usePersonalities() {
  const t = useTranslation()
  const personalities = [
    {
      id: 'rock',
      name: t('personality.rock.name'),
      icon: '🗿',
      shortKeyIndicators: [
        t('personality.rock.shortKeyIndicators.0'),
        t('personality.rock.shortKeyIndicators.1'),
        t('personality.rock.shortKeyIndicators.2'),
      ],
      keyIndicators: [
        t('personality.rock.keyIndicators.0'),
        t('personality.rock.keyIndicators.1'),
        t('personality.rock.keyIndicators.2'),
        t('personality.rock.keyIndicators.4'),
        t('personality.rock.keyIndicators.5'),
        t('personality.rock.keyIndicators.6'),
        t('personality.rock.keyIndicators.7'),
        t('personality.rock.keyIndicators.8'),
        t('personality.rock.keyIndicators.9'),
      ],
      shortRecommendations: [
        t('personality.rock.shortRecommendations.0'),
        t('personality.rock.shortRecommendations.1'),
        t('personality.rock.shortRecommendations.2'),
      ],
      recommendations: [
        t('personality.rock.recommendations.0'),
        t('personality.rock.recommendations.1'),
        t('personality.rock.recommendations.2'),
        t('personality.rock.recommendations.3'),
        t('personality.rock.recommendations.4'),
      ],
      hobbies: [
        'Team sports',
        'Football',
        'Fan Clubs',
        'Stadiums',
        'Activities with a social component',
      ],
    },
    {
      id: 'hand',
      name: t('personality.hand.name'),
      icon: '👋',
      shortKeyIndicators: [
        t('personality.hand.shortKeyIndicators.0'),
        t('personality.hand.shortKeyIndicators.1'),
        t('personality.hand.shortKeyIndicators.2'),
        t('personality.hand.shortKeyIndicators.3'),
      ],
      keyIndicators: [
        t('personality.hand.keyIndicators.0'),
        t('personality.hand.keyIndicators.1'),
        t('personality.hand.keyIndicators.2'),
        t('personality.hand.keyIndicators.4'),
        t('personality.hand.keyIndicators.5'),
        t('personality.hand.keyIndicators.6'),
        t('personality.hand.keyIndicators.7'),
      ],
      shortRecommendations: [
        t('personality.hand.shortRecommendations.0'),
        t('personality.hand.shortRecommendations.1'),
        t('personality.hand.shortRecommendations.2'),
        t('personality.hand.shortRecommendations.3'),
      ],
      recommendations: [
        t('personality.hand.recommendations.0'),
        t('personality.hand.recommendations.1'),
        t('personality.hand.recommendations.2'),
        t('personality.hand.recommendations.3'),
        t('personality.hand.recommendations.4'),
        t('personality.hand.recommendations.5'),
      ],
      hobbies: [
        'Golf',
        'Tennis',
        'Boating',
        'VIP lounges',
        'Activities with certain exclusivity',
      ],
    },
    {
      id: 'paper',
      name: t('personality.paper.name'),
      icon: '📄',
      shortKeyIndicators: [
        t('personality.paper.shortKeyIndicators.0'),
        t('personality.paper.shortKeyIndicators.1'),
        t('personality.paper.shortKeyIndicators.2'),
        t('personality.paper.shortKeyIndicators.3'),
      ],
      keyIndicators: [
        t('personality.paper.keyIndicators.0'),
        t('personality.paper.keyIndicators.1'),
        t('personality.paper.keyIndicators.2'),
        t('personality.paper.keyIndicators.4'),
        t('personality.paper.keyIndicators.5'),
      ],
      shortRecommendations: [
        t('personality.paper.shortRecommendations.0'),
        t('personality.paper.shortRecommendations.1'),
        t('personality.paper.shortRecommendations.2'),
        t('personality.paper.shortRecommendations.3'),
      ],
      recommendations: [
        t('personality.paper.recommendations.0'),
        t('personality.paper.recommendations.1'),
        t('personality.paper.recommendations.2'),
        t('personality.paper.recommendations.3'),
      ],
      hobbies: [
        'Chess',
        'Strategy games',
        'Activities requiring patience/endurance',
      ],
    },
    {
      id: 'scissors',
      name: t('personality.scissor.name'),
      icon: '✂️',
      shortKeyIndicators: [
        t('personality.scissor.shortKeyIndicators.0'),
        t('personality.scissor.shortKeyIndicators.1'),
        t('personality.scissor.shortKeyIndicators.2'),
      ],
      keyIndicators: [
        t('personality.scissor.keyIndicators.0'),
        t('personality.scissor.keyIndicators.1'),
        t('personality.scissor.keyIndicators.2'),
        t('personality.scissor.keyIndicators.4'),
        t('personality.scissor.keyIndicators.5'),
        t('personality.scissor.keyIndicators.6'),
      ],
      shortRecommendations: [
        t('personality.scissor.shortRecommendations.0'),
        t('personality.scissor.shortRecommendations.1'),
        t('personality.scissor.shortRecommendations.2'),
      ],
      recommendations: [
        t('personality.scissor.recommendations.0'),
        t('personality.scissor.recommendations.1'),
        t('personality.scissor.recommendations.2'),
        t('personality.scissor.recommendations.3'),
      ],
      hobbies: ['VIP (elite) sports', 'Golf'],
    },
  ]

  const getById = (id) => {
    const unknown = {
      id: 'unknown',
      icon: '❓',
      keyIndicators: [],
      shortKeyIndicators: [],
      recommendations: [],
      shortRecommendations: [],
      hobbies: [],
    }

    return personalities
      .concat(unknown)
      .filter((p) => p.id === id)
      .shift()
  }

  return {
    personalities,
    getById,
  }
}
