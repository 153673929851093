import dayjs from 'dayjs'

export default function useDate() {
  const parse = (date) => dayjs(date).toDate()

  const dateString = (date) => dayjs(date).format('YYYY-MM-DD')

  const difference = (date1, date2) => dayjs(date1).diff(dayjs(date2), 'days')

  const getMonthName = (month) => {
    const date = dayjs().day(0).month(month)

    return date.toDate().toLocaleString('default', { month: 'long' })
  }

  const getDayName = (day) => {
    const date = dayjs().day(day)

    return date.toDate().toLocaleString('default', { weekday: 'short' })
  }

  const addDays = (days, toDate) => {
    const date = dayjs(toDate).add(days, 'day')

    return date.toDate()
  }

  return {
    parse,
    dateString,
    difference,
    getDayName,
    getMonthName,
    addDays,
  }
}
