import { useQuery, useMutation, useQueryClient } from 'react-query'
import useAccessToken from '../useAccessToken'
import useApi from '../useApi'
import useDate from '../useDate'

export default function useUser() {
  const client = useQueryClient()
  const { dateString } = useDate()
  const { getAccessToken, isSignedIn } = useAccessToken()
  const { getMe, getOrganization, getUserFollowUps } = useApi({
    getAccessToken,
  })

  function useGetMe() {
    const result = useQuery(['user'], () => getMe(), {
      enabled: isSignedIn,
    })

    return {
      ...result,
      user: result.data,
    }
  }

  function useGetUserFollowUps({ organizationId, date, userId }) {
    const result = useQuery(
      ['follow_ups', { date: dateString(date), organizationId, userId }],
      () =>
        getUserFollowUps({ userId, organizationId, date: dateString(date) }),
      {
        enabled: isSignedIn && !!organizationId && !!userId,
      },
    )

    return {
      ...result,
      follow_ups: result.data,
    }
  }

  function useGetOrganization({ organizationId }) {
    const result = useQuery(
      ['organization', { organizationId }],
      () => getOrganization({ organizationId }),
      {
        enabled: isSignedIn && !!organizationId,
      },
    )

    return {
      ...result,
      organization: result.data,
    }
  }

  function useGetCurrentOrganizationId({ user }) {
    const result = useQuery(
      ['currentOrganizationId'],
      () => {
        const orgId = localStorage.getItem('current_organization_id')

        if (!orgId) {
          localStorage.setItem(
            'current_organization_id',
            user.organizations[0].id,
          )

          return user.organizations[0].id
        }

        return localStorage.getItem('current_organization_id')
      },
      {
        enabled: isSignedIn && !!user,
      },
    )

    return {
      ...result,
      currentOrganizationId: result.data,
    }
  }

  function useSetCurrentOrganizationId() {
    const result = useMutation(
      (organizationId) => {
        localStorage.setItem('current_organization_id', organizationId)
      },
      {
        onSuccess() {
          client.invalidateQueries(['currentOrganizationId'])
        },
      },
    )

    return {
      ...result,
      setCurrentOrganizationId: result.mutate,
      invalidate: () => {
        client.cancelQueries(['user'])
        client.cancelQueries(['organization'])
        client.cancelQueries(['currentOrganizationId'])
        localStorage.removeItem('current_organization_id')
      },
    }
  }

  return {
    useGetMe,
    useGetUserFollowUps,
    useGetOrganization,
    useGetCurrentOrganizationId,
    useSetCurrentOrganizationId,
  }
}
