import React from 'react'
import Script from 'next/script'

export default function Analytics() {
  return (
    <>
      <Script
        data-hostname="app.relationyst.com"
        src="https://scripts.simpleanalyticscdn.com/latest.js"
      />
      <noscript>
        {/* eslint-disable @next/next/no-img-element */}
        <img
          src="https://queue.simpleanalyticscdn.com/noscript.gif"
          alt=""
          referrerPolicy="no-referrer-when-downgrade"
        />
      </noscript>
    </>
  )
}
