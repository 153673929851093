import React, { useEffect } from 'react'
import useFirebase from '../../hooks/useFirebase'
import Spinner from '../../assets/spinner.svg'
import { useApp } from '../../hooks/useAppContext'

export default function RouteGuard({ publicPaths, children }) {
  const { router } = useApp()
  const { authUser, loadingUser, signOut } = useFirebase()
  const needsLogin =
    !publicPaths.includes(router.asPath.split('?')[0]) && !authUser

  useEffect(() => {
    if (loadingUser) {
      return
    }

    if (!authUser && !publicPaths.includes(router.asPath.split('?')[0])) {
      window.location = `login?returnUrl=${encodeURIComponent(router.asPath)}`
    }
  }, [router, loadingUser, authUser, publicPaths, signOut])

  if (loadingUser || needsLogin) {
    return (
      <main>
        <div className="flex items-center justify-center w-full h-screen">
          <Spinner className="w-full text-indigo-500" width={40} height={40} />
        </div>
      </main>
    )
  }

  return children
}
